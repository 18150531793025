<template>
    <div class="right">
        <div>
          <breadCrumb :siderContent="siderName" />
        </div>
        <div class="c_box">
          <div class="leftone">
            <div>
              <a-table
                :columns="columns"
                :data-source="outArr"
                :rowKey="
                  (record, index) => {
                    return index;
                  }
                "
                 :pagination="{
                  current:currentTa,
                  total:totalTa,
                  pageSize:10,
                }"
                @change="handleTableChange"
                :locale="{emptyText: '暂无数据'}"
              >
                <span slot="icon" slot-scope="text, item"  @click="clickDe(item)">
                  <div class="delet" >
                    <a-icon style="color: #ff3b30" type="close" />
                  </div>
                </span>
                <p slot-scope="record" style="margin: 0">
                  {{ record }}
                </p>
              </a-table>
            </div>
            <div class="myButton">
              <a-button class="clearBtn button" @click="clickEmpty">清空</a-button>
            </div>
          </div>
          <div class="rightone stork_right" >
            <div class="myInput">
               <a-input class="input" placeholder="请输入搜索内容"  v-model="goods_no"  @change="onSearch"/>  
            </div>
            <a-tabs default-active-key="1" type="card"  @change="callback">
                <a-tab-pane tab="全部商品"  key="0">
                <ul class="stock_goods">
                  <li
                    v-for="(item, index) in goodsListArr"
                    :key="index"
                    @click="clickEveryGood(item)"
                  >
                    <div class="zhe"  v-if="item.is_stockout===2"></div>
                    <div class="goods_img">
                      <img :src="item.preview_url" alt=""  v-if="item.preview_url"/>
                      <img src="@/assets/defaultImg.png" alt=""  v-if="!item.preview_url"/>
                    </div>
                    <div class="goods_name">
                      {{ item.goods_name }}
                    </div>
                    <div class="stock_num">
                      <span> {{ item.goods_price }}<span v-if="item.goods_unit">/{{item.goods_unit}}</span></span>
                      <span>库存{{ item.stock_total }}<span v-if="item.is_weighing==2">(千克)</span></span>
                    </div>
                    <div class="replace-item" v-if="item.is_soldout===2">
                      <div class="jiao"></div>
                      <div class="desc">已售罄</div>
                    </div>
                    <div class="replace-item" v-if="item.limited_stock_total !=0.00">
                      <div class="jiao"></div>
                      <div class="desc">限量沽清</div>
                    </div>
                  </li>
                </ul>
              </a-tab-pane> 
               <a-tab-pane  v-for="(item) in tabList" :key="item.category_id" :tab="item.name" >
                <ul class="stock_goods">
                  <li
                    v-for="(item, index) in goodsListArr"
                    :key="index"
                    @click="clickEveryGood(item)"
                  >
                    <div class="zhe"  v-if="item.is_stockout===2"></div>
                    <div class="goods_img">
                      <img :src="item.preview_url" alt=""  v-if="item.preview_url"/>
                      <img src="@/assets/defaultImg.png" alt=""  v-if="!item.preview_url"/>
                    </div>
                    <div class="goods_name">
                      {{ item.goods_name }}
                    </div>
                    <div class="stock_num">
                      <span> {{ item.goods_price }}<span v-if="item.goods_unit">/{{item.goods_unit}}</span></span>
                      <span>库存{{ item.stock_total }}<span v-if="item.is_weighing==2">(千克)</span></span>
                    </div>
                    <div class="replace-item" v-if="item.is_stockout===2">
                      <div class="jiao"></div>
                      <div class="desc">已售罄</div>
                    </div>
                    <div class="replace-item" v-if="item.limited_stock_total !=0.00">
                      <div class="jiao"></div>
                      <div class="desc">限量沽清</div>
                    </div>
                  </li>
                </ul>      
              </a-tab-pane>
            </a-tabs>    
            <div>             
            </div> 
             <a-pagination v-model="current" :total="total"  @change="changePage" :defaultPageSize="16"/>          
          </div>
        </div>
      </div>
</template>
<script>
import breadCrumb from "@/components/BreadCrumb/BreadCrumb";
import {stockout,stockoutList} from '@/request/stock.js';
import {categoryList,goodsList} from '@/request/business.js';
const columns = [
  { title: "商品", dataIndex: "goods_name", key: "name" },
  { title: "", scopedSlots: { customRender: "icon" } },
];
export default {
  props: [],
  components: {
  breadCrumb,
  },
  data() {
    return {
        siderNumber: 0,
        columns,
        siderName: "商品沽清", //sider切换内容
        goodsListArr: [], //商品列表
        outArr:[],//沽清列表
        everyGoods:'',//点击每条商品
        tabList:[],//商品分类
        goods_no:"",//商品搜索
        total:0,
        current:1,//当前页
        category_id:'',//商品分类
        currentTa:1,//表格当前页
        totalTa:0,//表格数据
    };
  },
  mounted(){
    this.goodsRequest();
    this.tabRequest();
    this.stockListRequest()
  },
  methods: {
    callback(key){ //点击tabs切换
    this.current=1;
    this.category_id=key;
    this.goodsRequest()
    },
    handleTableChange(e){ //点击分页
      this.currentTa = e.current;
      this.stockListRequest()
    },
    goodsRequest(){    //商品列表请求
      const message={
        page:this.current,
        category_id:  parseInt(this.category_id) ? parseInt(this.category_id): "",
        page_size:16,
        goods_name:this.goods_no,
      }   
      goodsList(message)
            .then((res) => {
              if(res.data.status===200){
                const list =res.data.data.list && res.data.data.list;
                this.goodsListArr=list.data && list.data;
                this.total=list.total &&  list.total            
              }           
            })
            .catch(result => {
              return false;
            })
    },
    clickEveryGood(item){
      if(item && item.is_stockout ===2){
        this.$message.warn('该商品已售罄',1.5);
      }else if(item && item.is_stockout ===1){
        this.everyGoods=item;
        const object={
          goods_name:this.everyGoods && this.everyGoods.goods_name,
          goods_id:this.everyGoods && this.everyGoods.goods_id,
        };
        this.outArr.push(object);
        this.stockoutRequest(item.goods_id,1,1)
      }   
    },
    tabRequest(){  //商品分类请求
      categoryList()
            .then((res) => {
              if(res.data.status===200){
                const list =res.data.data.list && res.data.data.list;
                this.tabList=list; 
              }           
            })
            .catch(result => {
              return false;
            })
    },
    onSearch(){ //商品列表搜索
      this.goodsRequest()
    },
    clickEmpty(){ //清空按钮
       this.stockoutRequest(0,2,2)
    },
    changePage(current){ //点击分页
      this.current = current;
      this.goodsRequest()
    },
    stockoutRequest(goods_id,type,number){ //商品盘点请求
       const _this=this;
        stockout({
          goods_id:goods_id,
          type:type,
          is_all:number,//是否全部清空
          })
        .then((res) => {
          if(res.data.status===200){
              // _this.$message.success('沽清成功',1.5);
              _this.goodsRequest();
              _this.stockListRequest()
            }else{
               _this.$message.warn('沽清失败',1.5);
            }              
          })
        .catch(result => {
            return false;
      })
    },
    clickDe(item){ //点击删除按钮
    this.stockoutRequest(item.goods_id,2,1);
    },
    stockListRequest(){//沽清列表
       const _this=this;
        stockoutList({
         page:_this.currentTa,
         listRows:10
          })
        .then((res) => {
          if(res.data.status===200){
           const list =res.data.data.list && res.data.data.list;
           _this.totalTa=res.data.data.list.total && res.data.data.list.total;
           _this.outArr=res.data.data.list.data && res.data.data.list.data;
            }             
          })
        .catch(result => {
            return false;
      })
    }
  },
};
</script>
<style lang="less" scoped>
  .right {
    height: 100%;
    width: 100%;
  }
  .c_box{
    height: calc(100% - 5.5vh);
    width: 100%;
    display: flex;
  }
  .leftone {
    position: relative;
    .ant-table-wrapper {
      width: 26vw;
    }
    .myButton{
      position: absolute;
      bottom: 2vh;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      flex-direction: column;
      .button {
        width: 18vw;
        height: 4.8vh;
        border-radius: 0.5vh;
        font-size: 1.8vh;
        margin-top: 1vh;
      }
    }
    /deep/.ant-table {
      font-size: 1.8vh;
    }
    /deep/.ant-table-thead {
      font-size: 1.8vh;
    }
  }
  .rightone {
    padding: 1vh;
    flex: 1;
    background-color: #edeef2;
    position: relative;
    .myInput{
      position: absolute;
      right: 1.5vw;
      top: 1vh;
      z-index: 10;
      .input {
        width: 20vh;
        height: 4.1vh;
      }
    }
    /deep/.ant-tabs{
      width: 100%;
    }
    .stock_goods{
      li{
        position: relative;
        overflow: hidden;
        .zhe{
          position: absolute;
          border-radius: 0.5vh;
          left: 0;
          top:0;
          bottom: 0;
          right: 0;
          background:#00000030;
        }
        .replace-item {
          right: 0;
          top: 0;
          position: absolute;
          color: #ffffff;
          font-size: 12px;
          .jiao, .desc {
            position: absolute;
            top:0;
            right: 0;
          }
          .jiao {
            width: 44px;
            height: 80px;
            transform: rotate(-50deg);
            -webkit-transform: rotate(-50deg);
            -moz-transform: rotate(-50deg);
            -ms-transform: rotate(-50deg);;
            top: -30px;
            right: -10px;
            background-color: #ff685d;
            padding:0;
          }
          .desc {
            transform: rotate(40deg);
            width: 48px;
            text-align: center;
            top: 7px;
            right: -5px;
            font-size: 12px;;
          }
        }
      }
    }
  }
  .ant-pagination{
    text-align: right;
    padding-right: 0.5vw;
  }
  /deep/.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab {
    margin: 0vh 1vh !important;
  }
  /deep/.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-nav-container {
    height: 46px;
  }
  /deep/.ant-pagination-prev {
    min-width: 3vh;
    height: 3.6vh;
    line-height: 3.6vh;
    margin-right: 0.5vh;
  }
  /deep/.ant-pagination-next {
    min-width: 3vh;
    height: 3.6vh;
    line-height: 3.6vh;
  }
  /deep/.ant-pagination-item {
    min-width: 3vh;
    height: 3.6vh;
    line-height: 3.6vh;
    margin-right: 0.5vh;
  }
  /deep/.ant-table-pagination.ant-pagination {
    margin: 1vh 3vh;
  }
  /deep/.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab {
    border-radius: 0.5vh 0.5vh 0.5vh 0.5vh;
    font-size: 1.8vh;
    text-align: center;
    line-height: 4vh;
    height: 4.1vh;
    color: black;
  }

  .delet{
    cursor: pointer;
  }

  /deep/.ant-table-content{
    height:70vh;
  }
  /deep/.ant-tabs-bar{
    width:38vw;
    margin-bottom: 1vh;
  }
  /deep/.ant-tabs-nav-container{
    line-height: 46px;
  }
</style>